<template>
	<div>
		<div class="indexUser">
			<div>
				<!-- header  点击 -->
				<headers :userList="userList"></headers>
			</div>
			<div class="bg1 userBox">
				<div class="wrap bgw">
					<div class="box">
						<div class="title">发票信息管理</div>
						<div v-if="state == 0" class="content">
							<div class="titletype">
								<div class="fl w">发票类型</div>
								<div class="fl">
									<p @click="type = 0" :class="{active:type == 0}">
										<span></span> 个人
									</p>
									<p @click="type = 1" :class="{active:type == 1}">
										<span></span> 企事业单位
									</p>
								</div>
							</div>
							<div class="listbox" v-if="type==0">
								<div class="txt">
									<div class="fl w">发票抬头</div>
									<div class="fl">
										<input type="text" v-model="title" />
									</div>
								</div>
								<div class="txt">
									<div class="fl w">税号</div>
									<div class="fl">
										<input type="text" v-model="tax_no" />
									</div>
								</div>
								<div class="txt">
									<div class="fl w">手机号:</div>
									<div class="fl">
										<input type="text" v-model="mobile" />
									</div>
								</div>
								<div class="txt">
									<div class="fl w">邮箱:</div>
									<div class="fl">
										<input type="text" v-model="email" />
									</div>
								</div>
							</div>
							<div class="listbox" v-if="type==1">
								<div class="txt">
									<div class="fl w">发票抬头</div>
									<div class="fl">
										<input type="text" v-model="title" />
									</div>
								</div>
								<div class="txt">
									<div class="fl w">税号</div>
									<div class="fl">
										<input type="text" v-model="tax_no" />
									</div>
								</div>
								<div class="txt">
									<div class="fl w">手机号:</div>
									<div class="fl">
										<input type="text" v-model="mobile" />
									</div>
								</div>
								<div class="txt">
									<div class="fl w">邮箱:</div>
									<div class="fl">
										<input type="text" v-model="email" />
									</div>
								</div>
								<div class="txt">
									<div class="fl w">公司地址:</div>
									<div class="fl">
										<input type="text" v-model="company_address" />
									</div>
								</div>
								<div class="txt">
									<div class="fl w">公司电话:</div>
									<div class="fl">
										<input type="text" v-model="company_mobile" />
									</div>
								</div>
								<div class="txt">
									<div class="fl w">开户银行:</div>
									<div class="fl">
										<input type="text" v-model="bank_name" />
									</div>
								</div>
								<div class="txt">
									<div class="fl w">银行账号:</div>
									<div class="fl">
										<input type="text" v-model="bank_no" />
									</div>
								</div>
							</div>
							<div class="txt">
								<div class="fl w"></div>
								<div class="sq">
									<button @click="checking()">申请</button>
								</div>
							</div>
						</div>
						<div v-if="state == 1" class="content state_2">
							<p>
								<span>发票类型</span>
								{{ fapiaoInfo.type == 0 ? '个人' : '企业'}}
							</p>
							<p>
								<span>发票抬头</span>
								{{ fapiaoInfo.title }}
							</p>
							<p>
								<span>税号</span>
								{{ fapiaoInfo.tax_no }}
							</p>
							<p>
								<span>手机号</span>
								{{ fapiaoInfo.mobile }}
							</p>
							<p>
								<span>邮箱</span>
								{{ fapiaoInfo.email }}
							</p>
							<div class="company" v-if="fapiaoInfo.type==1">
								<p>
									<span>公司地址</span>
									{{ fapiaoInfo.company_address }}
								</p>
								<p>
									<span>公司电话</span>
									{{ fapiaoInfo.company_mobile }}
								</p>
								<p>
									<span>开户银行</span>
									{{ fapiaoInfo.bank_name }}
								</p>
								<p>
									<span>银行卡号</span>
									{{ fapiaoInfo.bank_no }}
								</p>
							</div>
							<button v-if="fapiaoInfo.status_text == '待审核'">审核中</button>
							<div v-else>
								<button @click="updataFaPiao">修改</button>
								<button @click="$router.push('/user/index')">确认</button>
							</div>
						</div>

					</div>
				</div>
			</div>
			<div>
				<publicBottom></publicBottom>
			</div>
		</div>
	</div>
</template>
<script>
	import headers from "./header"; // 头部 TOP 和header
	import publicBottom from "@/components/public/public_bottom"; //底部

	import {
		fapiao_list
	} from "@/request/user";
	import {
		adds
	} from "@/request/user";
	import {
		usergetinfo
	} from "@/request/user";

	export default {
		components: {
			headers,
			publicBottom
		},
		data() {
			return {
				list: [],
				title: "",
				tax_no: "",
				type: 0,
				userList: [], // 个人信息
				state: 1,
				fapiaoInfo: {},
				email: "",
				mobile: "",
				company_address: "",
				company_mobile: "",
				bank_name: "",
				bank_no: "",
			};
		},
		created() {
			let datas = localStorage.getItem("lzyyToken");
			let Athis = this;
			if (datas != null) {
				this.$tokens = datas;
			} else {
				this.$message.error("登录错误，请重新登录");
				setTimeout(function() {
					Athis.$router.replace({
						path: "/land"
					});
				}, 1000);
			}

			this.init();
			this.start();
			// this.state=this.$route.query.state
		},
		methods: {
			checking() {
				if (this.type == 0) {
					if (this.title == "") {
						this.$message.error("请输入发票抬头");
					} else if (this.tax_no == "") {
						this.$message.error("请输入税号");
					} else if (this.type == -1) {
						this.$message.error("请选择发票类型");
					} else if (this.mobile == "") {
						this.$message.error("请输入手机号码");
					} else if (this.email == "") {
						this.$message.error("请输入邮箱地址");
					} else {
						this.send();
					}
				} else {
					if (this.title == "") {
						this.$message.error("请输入发票抬头");
					} else if (this.tax_no == "") {
						this.$message.error("请输入税号");
					} else if (this.type == -1) {
						this.$message.error("请选择发票类型");
					} else if (this.mobile == "") {
						this.$message.error("请输入手机号码");
					} else if (this.email == "") {
						this.$message.error("请输入邮箱地址");
					} else if (this.company_address == "") {
						this.$message.error("请输入公司地址");
					} else if (this.company_address == "") {
						this.$message.error("请输入公司电话");
					} else if (this.company_address == "") {
						this.$message.error("请输入开户银行");
					} else if (this.company_address == "") {
						this.$message.error("请输入银行卡号");
					} else {
						this.sendtcompany();
					}
				}
			},
			sendtcompany() {
				this.$axios.post("/api/user_invoice/add", {
					token: this.$tokens,
					title: this.title,
					tax_no: this.tax_no,
					type: this.type,
					email: this.email,
					mobile: this.mobile,
					company_address: this.company_address,
					company_mobile: this.company_mobile,
					bank_name: this.bank_name,
					bank_no: this.bank_no,
				}).then(res => {
					console.log(res.data.code);
					if (res.data.code == 0) {
						this.$message.error(res.data.msg);
					} else {
						if (res.data.code == 1) {
							this.$message({
								message: "申请成功",
								type: "success"
							});
							this.start();
						}
					}
				})
			},
			send() {
				adds({
					token: this.$tokens,
					title: this.title,
					tax_no: this.tax_no,
					type: this.type,
					email: this.email,
					mobile: this.mobile,
				}).then(res => {
					if (res.code == 1) {
						this.$message({
							message: "申请成功",
							type: "success"
						});
						this.start();
					}
				});
			},
			start() {
				fapiao_list({
					token: this.$token
				}).then(res => {
					if (res.code == 1) {
						this.fapiaoInfo = res.data;
						this.state = 1;
					}
					this.list = res.data;
				});
			},
			init() {
				//   个人信息
				usergetinfo({
					token: this.$tokens
				}).then(res => {
					this.userList = res.data;
				});
			},
			updataFaPiao() {
				if(this.fapiaoInfo.type==1){
					this.type = this.fapiaoInfo.type;
					this.title = this.fapiaoInfo.title;
					this.tax_no = this.fapiaoInfo.tax_no;
					this.email = this.fapiaoInfo.email;
					this.mobile = this.fapiaoInfo.mobile;
					this.company_address=this.fapiaoInfo.company_address;
					this.company_mobile=this.fapiaoInfo.company_mobile;
					this.bank_name= this.fapiaoInfo.bank_name;
					this.bank_no=this.fapiaoInfo.bank_no;
				}else if(this.fapiaoInfo.type==0){
					this.type = this.fapiaoInfo.type;
					this.title = this.fapiaoInfo.title;
					this.tax_no = this.fapiaoInfo.tax_no;
					this.email = this.fapiaoInfo.email;
					this.mobile = this.fapiaoInfo.mobile;
				}
				this.state = 0;
			}
		}
	};
</script>

<style lang="less" scoped>
	.active {
		span {
			background: #4eeec8;
			border: none !important;
		}

		color: #4eeec8;
	}

	.box {
		font-size: 22px;

		.title {
			height: 69px;
			line-height: 69px;
			padding-left: 44px;

		}

		.titletype {
			height: 70px;
		}

		.content {
			margin-top: 40px;
			min-height: 600px;
			padding-left: 44px;

			.listbox div {
				height: 46px;
				overflow: hidden;
				margin-bottom: 42px;
			}

			p {
				display: inline-block;
				width: 200px;
				position: relative;
				height: 46px;
				text-indent: 50px;
			}

			span {
				position: absolute;
				top: 0;
				left: 0;
				width: 28px;
				height: 28px;
				border: 1px solid rgba(168, 168, 168, 1);
				border-radius: 50%;
			}
		}

		.sq {
			height: 100px;
		}

		button {
			width: 150px;
			height: 45px;
			font-size: 22px;
			cursor: pointer;
		}

		.w {
			width: 160px;
			height: 46px;
		}

		input {
			border: 1px solid #a8a8a8 !important;
			width: 400px;
			height: 46px;
			box-sizing: border-box;
			font-size: 22px;
			text-indent: 10px;
		}

		.state_2 {
			.company {
				margin: 0;
			}

			p {
				display: block;
				width: 500px;
				overflow: hidden;
				margin-bottom: 10px;
			}

			span {
				display: inline-block;
				position: static;
				width: 230px;
				border: 0px solid #a8a8a8;
				border-radius: 0%;
				margin-left: -100px;
			}

			>button {
				margin-left: 190px;
				margin-top: 120px;
				border-radius: 10px;
				outline: none;
				cursor: no-drop;
			}

			div {
				height: 100px;
				margin-left: 190px;
				margin-top: 120px;
				button {
					margin-right: 50px;
					margin-top: 20px;
				}
			}
		}
	}
</style>
